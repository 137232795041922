import React, {useEffect, useRef} from 'react';

const Turnstile = ({siteKey, onSuccess}) => {
    const captchaRef = useRef(null);
    const scriptLoaded = useRef(false); // Проверка, был ли загружен скрипт
    const captchaRendered = useRef(false); // Проверка, была ли отрисована капча

    useEffect(() => {
        if (!scriptLoaded.current) {
            // Загрузка скрипта только один раз
            const script = document.createElement('script');
            script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);

            script.onload = () => {
                scriptLoaded.current = true; // Скрипт загружен
                renderCaptcha();
            };

            return () => {
                document.body.removeChild(script);
            };
        } else {
            // Если скрипт уже загружен, просто рендерим капчу
            renderCaptcha();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteKey, onSuccess]);

    const renderCaptcha = () => {
        if (window.turnstile && captchaRef.current && !captchaRendered.current) {
            window.turnstile.render(captchaRef.current, {
                sitekey: siteKey,
                callback: onSuccess,
            });
            captchaRendered.current = true; // Капча отрисована
        }
    };

    return <div className="turnstile-center" ref={captchaRef}></div>;
};

export default Turnstile;
