import React from 'react';

import logo from '../Images/navbar-logo.webp';
import ukassa from '../Images/ukassa.svg';
import { useTranslation } from 'react-i18next';



const FooterComponent = () => {

    const { t } = useTranslation();

    return (
        <div className="footer-container">
            <div className="footer-logo-legal-container">
                <div className="footer-logo-container flex place-items-center gap-4">
                    <img src={logo} alt="CelistraCraft logo" className="footer-logo"/>
                    <div className="footer-logo-text">
                        <h1 className="footer-h1 footer-main-h1">CELISTRACRAFT</h1>
                        <p className="footer-p">© 2020-2024 CelistraCraft Network</p>
                    </div>
                </div>
                <p className="footer-legal-p">{t("footer_company")}</p>
                <p className="footer-legal-p">{t("footer_companyInn")}</p>
                <p className="footer-legal-p">support@celistracraft.net</p>
            </div>
            <div className="footer-info-container">
                <h1 className="footer-h1">{t("footer_info")}</h1>
                <br/>
                <div className="footer-info-links">
                    <a href="https://blog.celistracraft.net/CelistraCraft-Rules" rel="noreferrer" target="_blank">
                        <p className="footer-link">{t("footer_rules")}</p>
                    </a>
                    <a href="/legal/offer.pdf" rel="noreferrer" target="_blank">
                        <p className="footer-link">{t("footer_publicOffer")}</p>
                    </a>
                    <a href="/legal/privacy.pdf" rel="noreferrer" target="_blank">
                        <p className="footer-link">{t("footer_privacy")}</p>
                    </a>
                </div>
            </div>
            <div className="footer-info-container">
                <h1 className="footer-h1">{t("footer_socials")}</h1>
                <br/>
                <div className="footer-info-links">
                    <a href="https://discord.gg/VtxfGqbaZM" rel="noreferrer" target="_blank">
                        <p className="footer-link">Discord</p>
                    </a>
                    <a href="https://t.me/celistracraft" rel="noreferrer" target="_blank">
                        <p className="footer-link">Telegram</p>
                    </a>
                    <a href="https://vk.com/celistracraft" rel="noreferrer" target="_blank">
                        <p className="footer-link">{t("footer_vkontakte")}</p>
                    </a>
                </div>
            </div>
            <div className="footer-systems-logos">
                <img src={ukassa} alt="Ukassa" className="footer-systems-logo"/>
                {/*<img src={stripe} alt="Stripe" className="footer-systems-logo"/>*/}
            </div>
        </div>
    );
};

export default FooterComponent;