import * as React from 'react';
import {useState} from 'react';

import grassblock from '../Images/grass-block.png';
import players from '../Images/players.png';
import book from '../Images/book.png';
import celistra from '../Images/celistra.png';
import discord from '../Images/discord.png';
import {useTranslation} from 'react-i18next';

const HowToJoinComponent = () => {

    const {t} = useTranslation();
    const [copyAdressButtonText, setCopyAdressButtonText] = useState(t('play_copyAdress'));

    const copyAdress = () => {
        navigator.clipboard.writeText("mc.celistracraft.net");
        setCopyAdressButtonText(t('play_copyAdressSuccess'));

        // set color to green
        const copyButton = document.querySelector(".copy");
        copyButton.style.backgroundColor = "#217926";

        setTimeout(() => {
            setCopyAdressButtonText(t('play_copyAdress'));
            copyButton.style.backgroundColor = "#00B4FF3F";
        }, 3000);
    }

    return (
        <div className="HowToJoin-container" id="play">
            <h2 className="skytowns-h2">{t("play_howToJoin")}</h2>
            <div className="first-step-container">
                <div className="HowToJoin-text">
                    <h3 className="HowToJoin-h3">{t("play_launcher")}</h3>
                    <p className="HowToJoin-p">{t("play_launcherSub")}</p>
                    <div className="HowToJoin-buttons">
                        <button
                            className="HowToJoin-button official animate-hover"
                            onClick={() => window.open('https://www.minecraft.net/ru-ru/download', '_blank')}
                        >
                            {t("play_launcherOfficial")}
                        </button>
                        <button
                            className="HowToJoin-button tl-legacy animate-hover"
                            onClick={() => window.open('https://llaun.ch/ru', '_blank')}
                        >
                            TL Legacy
                        </button>
                        <button
                            className="HowToJoin-button pojav animate-hover"
                            onClick={() => window.open('https://pojavlauncherteam.github.io/INSTALL.html', '_blank')}
                        >
                            {t("play_Pojav")}
                        </button>
                    </div>
                </div>
                <img src={grassblock} alt="Grass block" className="animate-hover HowToJoin-image"/>
            </div>
            <div className="first-step-container flex place-items-center">
                <div className="HowToJoin-text">
                    <h3 className="HowToJoin-h3">{t("play_register")}</h3>
                    <p className="HowToJoin-p">{t("play_registerSub")}</p>
                    <p className="HowToJoin-p">{t("play_registerSub2")}</p>
                    <p className="HowToJoin-p">{t("play_registerSub3")}</p>
                </div>
                <img src={players} alt="Players" className="animate-hover HowToJoin-image"/>
            </div>
            <div className="first-step-container flex place-items-center">
                <div className="HowToJoin-text">
                    <h3 className="HowToJoin-h3">{t("play_rules")}</h3>
                    <p className="HowToJoin-p">{t("play_rulesSub")}</p>
                    <a href="https://blog.celistracraft.net/CelistraCraft-Rules" rel="noreferrer" target="_blank"
                       className="HowToJoin-buttons">
                        <button className="HowToJoin-button rules animate-hover">{t("play_rulesOpen")}</button>
                    </a>
                </div>
                <img src={book} alt="Book" className="animate-hover HowToJoin-image"/>
            </div>
            <div className="first-step-container flex place-items-center">
                <div className="HowToJoin-text">
                    <h3 className="HowToJoin-h3">{t("play_join")}</h3>
                    <p className="HowToJoin-p">{t("play_joinSub")}</p>
                    <div className="HowToJoin-buttons">
                        <button onClick={(event) => copyAdress()}
                                className="HowToJoin-button copy animate-hover">{copyAdressButtonText}</button>
                    </div>
                </div>
                <img src={celistra} alt="CelistraCraft logo" className="animate-hover HowToJoin-image"/>
            </div>
            <div className="first-step-container flex place-items-center">
                <div className="HowToJoin-text">
                    <h3 className="HowToJoin-h3">{t("play_joinDiscord")}</h3>
                    <p id="error" className="HowToJoin-p">{t("play_joinDiscordSub")}</p>
                    <a href="https://discord.gg/VtxfGqbaZM" rel="noreferrer" target="_blank" className="HowToJoin-buttons">
                        <button id="store" className="HowToJoin-button discord-join animate-hover">{t("play_joinDiscordOpen")}</button>
                    </a>
                </div>
                <img src={discord} alt="CelistraCraft logo" className="animate-hover HowToJoin-image"/>
            </div>
        </div>
    );
};

export default HowToJoinComponent;