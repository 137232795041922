import CtaComponent from "./Components/CtaComponent";
import NavbarComponent from "./Components/NavbarComponent";
import SkyTownsComponent from "./Components/SkyTownsComponent";
import HowToJoinComponent from "./Components/HowToJoinComponent";
import ShopComponent from "./Components/ShopComponent";
import FooterComponent from "./Components/FooterComponent";


function App() {
  return (
      <div className="App" id="start">
          <NavbarComponent/>
          <CtaComponent/>
          <SkyTownsComponent/>
          <HowToJoinComponent/>
          <ShopComponent/>
          <FooterComponent/>
      </div>
  );
}

export default App;
