import React from 'react';
import BentoGridComponent from "./SkyTowns/BentoComponent";
import { useTranslation } from 'react-i18next';

const SkyTownsComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="skytowns-container" id="skytowns">
            <h2 className="skytowns-h2">{t("play_whatIs")}</h2>
            <BentoGridComponent />
        </div>
    );
};

export default SkyTownsComponent;