import React from 'react';
import { useTranslation } from 'react-i18next';

const CtaComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="cta-container">
            <div className="cta-content-container">
                <div className="cta-create-your-town">
                    <h1 className="cta-h1">{t('cta_createYourTown')} <br/>{t('cta_createYourTownLine')} <span className="cta-skytowns">{t('skytowns')}</span></h1>
                    <p className="cta-p">{t('cta_createYourTownSub')}</p>
                </div>
                <p className="cta-p"><b>IP:</b> mc.celistracraft.net <b>[ 1.20 - 1.21 ]</b></p>
                <div className="cta-buttons">
                    <a href="#play">
                        <button className="cta-button join">{t("cta_playNow")}</button>
                    </a>
                    <a href="https://discord.gg/VtxfGqbaZM" rel="noreferrer" target="_blank">
                        <button className="cta-button discord">{t("cta_discord")}</button>
                    </a>
                    <a href="https://skytowns.celistracraft.net" rel="noreferrer" target="_blank">
                        <button className="cta-button map">{t("cta_map")}</button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CtaComponent;