import React from 'react';
import image1 from '../../Images/skytowns-image-1.webp';
import image2 from '../../Images/skytowns-image-2.webp';
import image3 from '../../Images/skytowns-image-3.webp';
import image4 from '../../Images/skytowns-image-4.webp';
import image5 from '../../Images/skytowns-image-5.webp';
import image6 from '../../Images/skytowns-image-6.webp';
import image7 from '../../Images/skytowns-image-7.webp';
import image8 from '../../Images/skytowns-image-8.webp';
import image9 from '../../Images/skytowns-image-9.webp';
import image10 from '../../Images/skytowns-image-10.webp';
import image11 from '../../Images/skytowns-image-11.webp';
import { useTranslation } from 'react-i18next';


const BentoGridComponent = () => {
    const { t } = useTranslation();
    return (
        <div className="bento-grid-container">
            <div className="grid-item large animate-hover">
                <img src={image3} alt="Картинка шахты из мира экспедиций на режиме SkyTowns"/>
                <div className="item-overlay">{t("skytowns_mine")}<span className="grid-monsters">{t('skytowns_mineMonster')}</span>{t('skytowns_mineAnd')}<span className="grid-monsters">{t('skytowns_mineBosses')}</span></div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image1} alt="Картинка двух игроков смотрящих в подзорную трубу находясь на спавне на режиме SkyTowns"/>
                <div className="item-overlay">{t("skytowns_unite")}<br/>{t("skytowns_uniteLine")}<span className="grid-city">{t("skytowns_uniteTown")}</span></div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image2} alt="Картинка игрового персонажа Зачарования который находится на спавне на режиме SkyTowns"/>
                <div className="item-overlay">{t("skytowns_enchant")}<span className="grid-enchantments">{t("skytowns_enchantWord")}</span>{t("skytowns_enchantFinish")}</div>
            </div>
            <div className="grid-item wide animate-hover">
                <img src={image4} alt="Картинка с серверным уведомлением на экране в котором написано про надвигающийся катаклизм Песчаная буря"/>
                <div className="item-overlay">{t("skytowns_cataclism")}<span className="grid-cataclism">{t("skytowns_cataclismWord")}</span>{t("skytowns_cataclismFinish")}
                </div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image5} alt="Картинка с внутриигровым меню"/>
                <div className="item-overlay">{t("skytowns_navigation")}<span className="grid-globus">{t("skytowns_navigationWord")}</span></div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image8} alt="Картинка с игровыми персонажами лидеров игроков на спавне"/>
                <div className="item-overlay">{t("skytowns_leader")}<span className="grid-leaderboard">{t("skytowns_leaderWord")}</span>{t("skytowns_leaderFinish")}
                </div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image9} alt="Картинка ящиков с ресурсами на спавне на сервере селистракрафт"/>
                <div className="item-overlay">{t("skytowns_crates")}<span className="grid-crates">{t("skytowns_cratesWord")}</span>{t("skytowns_cratesFinish")}
                </div>
            </div>
            <div className="grid-item wide animate-hover">
                <img src={image6} alt="Картинка с декоративной мебелью (диваны, стулья, столы) доступной на режиме SkyTowns"/>
                <div className="item-overlay">{t("skytowns_decoration")}<span className="grid-decor">{t("skytowns_decorationWord")}</span>{t("skytowns_decorationFinish")}</div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image7} alt="Картинка с внутриигровым меню с выбором генерации для своего острова для своего будущего города на скайтаунс"/>
                <div className="item-overlay">{t("skytowns_generation")}<span className="grid-generation">{t("skytowns_generationWord")}</span>{t("skytowns_generationFinish")}</div>
            </div>
            <div className="grid-item medium animate-hover">
                <img src={image10} alt="Картинка с внутриигровым меню просмотра наборов ресурсов в Глобусе (серверное меню)"/>
                <div className="item-overlay">{t("skytowns_preview")}<span className="grid-kits">{t("skytowns_previewWord")}</span>{t("skytowns_previewFinish")}
                </div>
            </div>
            <div className="grid-item wide animate-hover">
                <img src={image11} alt="Картинка с монстром из ивента Осада на острове"/>
                <div className="item-overlay">{t("skytowns_siege")}<span className="grid-siege">{t("skytowns_siegeWord")}</span>{t("skytowns_siegeFinish")}
                </div>
            </div>
        </div>
    );
};

export default BentoGridComponent;


