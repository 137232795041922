import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import navbarLogo from '../Images/navbar-logo.webp';

const NavbarComponent = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'ru' : 'en';
        i18n.changeLanguage(newLang);
    };

    useEffect(() => {
        const handleScroll = (event) => {
            const hash = window.location.hash;
            if (hash) {
                const element = document.querySelector(hash);
                if (element) {
                    const yOffset = 50;
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    window.scrollTo({ top: y, behavior: 'smooth' });
                }
            }
        };

        window.addEventListener('hashchange', handleScroll, false);

        return () => {
            window.removeEventListener('hashchange', handleScroll, false);
        };
    }, []);

    return (
        <div className="navbar-container">
            <a href="#start">
                <div className="navbar-logo flex justify-center place-items-center gap-5">
                    <img className="navbar-logo-img" src={navbarLogo} alt="CelistraCraft logo"></img>
                    <h1>CELISTRACRAFT</h1>
                </div>
            </a>
            <div className="navbar-links">
                <ul>
                    <li className="animate-hover">
                        <a href="#skytowns">{t('skytowns')}</a>
                    </li>
                    <li className="animate-hover">
                        <a href="#play">{t('nav_play')}</a>
                    </li>
                    <li className="animate-hover">
                        <a href="#store">{t('nav_store')}</a>
                    </li>
                    <li className="animate-hover">
                        <a href="https://skytowns.celistracraft.net" rel="noreferrer" target="_blank">{t('nav_map')}</a>
                    </li>
                    <li className="animate-hover">
                        <a href="https://discord.gg/VtxfGqbaZM" rel="noreferrer" target="_blank">{t('nav_support')}</a>
                    </li>
                    <li className="animate-hover">
                        <button onClick={toggleLanguage}>
                            {i18n.language === 'en' ? 'Русский' : 'English'}
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default NavbarComponent;
