import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import axios from "axios";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

export default axiosInstance;