import React, { useEffect, useRef, useState } from 'react';
import stripeLogo from '../Images/stripe.svg';
import ukassa from '../Images/ukassa.svg';
import celistriks from '../Images/150.webp';
import { useTranslation } from 'react-i18next';
import Turnstile from "./Turnstile";

const ModalComponent = ({
                            onClose,
                            onProcess,
                            handleCaptchaSuccess,
                            nickname,
                            uuid,
                            currency,
                            amount,
                            email,
                            captchaSuccess
                        }) => {
    const modalRef = useRef(null);
    const [paymentSystemLogo, setPaymentSystemLogo] = useState('');
    const [isMobileView, setIsMobileView] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const checkMobile = () => {
            setIsMobileView(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    useEffect(() => {
        if (currency === 'EUR') {
            setPaymentSystemLogo(stripeLogo);
        } else {
            setPaymentSystemLogo(ukassa);
        }
    }, [currency]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const getFinalSum = (amount, currency) => {
        if (currency === 'RUB') {
            return amount;
        } else if (currency === 'EUR') {
            return amount * 100;
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-container" ref={modalRef}>
                <h2 className="skytowns-h2 text-center">{t("shop_confirmDonation")}</h2>
                <div className="modal-content">
                    <div className="modal-text">
                        <div className="modal-payment-system-container">
                            <p className="modal-payment-system-text">{t("shop_paymentSystem")}</p>
                            <img src={paymentSystemLogo} alt="Логотип выбранной платёжной системы (ЮКасса или Страйп)" className="modal-payment-system"/>
                        </div>
                        <div className="modal-player-information">
                            <p className="modal-payment-system-text">{t("shop_yourInformation")}</p>
                            <p className="shop-oferta"><span>{t("shop_nickname")}</span> {nickname}</p>
                            <p className="shop-oferta"><span>{t("shop_email")}</span> {email}</p>
                            <p className="shop-oferta" style={{display: 'inline-flex', alignItems: 'center'}}>
                                <span>{t("shop_amount")}&nbsp;</span>{getFinalSum(amount, currency)}
                                <img src={celistriks} className="modal-celistriks" alt="celistriks logo" style={{marginLeft: '5px'}}/>
                            </p>
                            <p className="shop-oferta">
                                <span>{t("shop_amountPay")}</span> {amount}&nbsp;{currency === 'RUB' ? '₽' : '€'}
                            </p>
                        </div>
                    </div>
                    <div className="modal-skin">
                        <img src={`https://crafthead.net/armor/body/${uuid}`} alt={nickname}/>
                    </div>
                </div>
                <div className="modal-oferta">
                    <p className="shop-oferta">{t("shop_acceptText")}<a href="/legal/offer.pdf"
                                                                        target="_blank">{t("shop_acceptLink")}</a>{t("shop_acceptAnd")}<a
                        href="/legal/privacy.pdf" target="_blank">{t("shop_acceptLink2")}</a>.</p>
                    {isMobileView &&
                        <React.Fragment>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: 'auto'
                            }}></div>
                            <Turnstile siteKey={process.env.REACT_APP_SITE_KEY} onSuccess={handleCaptchaSuccess}/>
                        </React.Fragment>
                    }
                    <button onClick={onProcess} className="shop-buy-button HowToJoin-button" disabled={!captchaSuccess}>
                        {t("shop_Pay")}
                    </button>
                </div>
                <br/>
                {!isMobileView &&
                    <Turnstile siteKey={process.env.REACT_APP_SITE_KEY} onSuccess={handleCaptchaSuccess}/>
                }
            </div>
        </div>
    );
};

export default ModalComponent;